// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import RegistrationConfirm from '../components/user/registration-confirmation';
import crmClientService from '../services/crmClientService';

const RegistrationThankyou = (props) => {
  return (
    <Layout>
      <SEO title="User Register" />
      <RegistrationConfirm 
          userEmail={
            !!props.location && !!props.location.state ? props.location.state.userEmail : null
          }
          context={
            !!props.location && !!props.location.state ? props.location.state.context : 'registration'
          } />
    </Layout>
  );
};

export default RegistrationThankyou;
