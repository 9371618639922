import React, { useState } from 'react';
import styles from './registration-confirmation.module.scss';
import HeadingFullsize from '../common/heading-full-size/full-size-heading';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import locale from '../../locale/locale';
import crmService from '../../services/crmClientService';
import ButtonLoader from '../common/loaders/button-loader';
import awaitHandler from '../../libs/asyncHandler';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const RegistrationConfirm = ({ context, userEmail }) => {
  const imgClementinesPlaceholder = useImageStaticQuery('Carrots.png');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const backgroundFluidImageStack = {
    lg: imgClementinesPlaceholder.lg.fluid,
    xl: imgClementinesPlaceholder.childImageSharp.fluid,
  };
  const isRegistration = context === 'registration';
  const pageData = (isRegistration ? locale.registrationConfirmation : locale.passwordResetConfirmation);
  const [submission, setSubmission] = useState(false);
  const resendEmail = async () => {
    if (submission) {
      return;
    }

    setSubmission(true);

    let token = await executeRecaptcha('thankYou');
    crmService.setRecaptchaToken(token);

    // Try the call and see if it's successful
    const [, err] = await awaitHandler(crmService[isRegistration ?
      'registrationEmailTokenResend' :
      'resetPasswordEmailTokenResend'
      ](userEmail));

    // console.log('token reset', userEmail, result, err);

    if (err) {
      // Handle?
      const token = await executeRecaptcha('thankYou');
      crmService.setRecaptchaToken(token);
    } else {
      crmService.resetRecaptchaToken();
    }

    // Always end the loader.
    setSubmission(false);
  };

  return (
    <div className={styles.registrationConfirmationContainer}>
      <HeadingFullsize
        imageStack={backgroundFluidImageStack}
        title={pageData.pageTitle}
        subTitle={pageData.subTitle}
        description={pageData.description}
        force={true}
      >
        {!!userEmail && (<>
          <div className={styles.registrationConfirmationResendLine}>{pageData.resendEmail}</div>
          <div className={styles.registrationConfirmationResendButton}
               onClick={() => {
                 resendEmail();
               }}>
            {pageData.resendButton}
            {submission && <ButtonLoader/>}
          </div>
        </>)
        }
      </HeadingFullsize>
    </div>
  );
};

export default RegistrationConfirm;
